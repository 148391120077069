<div *ngIf="productDetails$ | async as product" class="d-grid">
  <div class="row">
    <ng-container *ngIf="btnVariant$ | async as buttons">
      <ng-container *ngFor="let btn of buttons">
        <div *ngIf="btn === productVariant.NEW" [ngClass]="(buttons?.length > 1) ? 'col-6': 'col-12'">
          <button type="button" [ngClass]="selectedBtnFlag ? 'cstm-btn-primary' : 'cstm-btn-light'"
            (click)="onVariantChange(productVariant.NEW)">{{"productDetail.new" |cxTranslate}}</button>
        </div>
        <div *ngIf="btn === productVariant.REFURBISHED" [ngClass]="(buttons?.length > 1) ? 'col-6': 'col-12'">
          <button type="button" [ngClass]="selectedBtnFlag ? 'cstm-btn-light' : 'cstm-btn-primary'"
            (click)="onVariantChange(productVariant.REFURBISHED)">{{"productDetail.refurbished" |cxTranslate}}</button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>